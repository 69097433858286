<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-content"
        :class="{ 'show': mqShallShowLeftSidebar }"
      >
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>
        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <!-- <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :src="profileUserMinimalData.avatar"
                variant="transparent"
                badge
                badge-variant="success"
                @click.native="$emit('show-user-profile')"
              /> -->
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search..."
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <!-- Chats Title -->
          <h4 class="chat-list-title">
            Chats
          </h4>
{{ conversationsList }}
          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <b-overlay :show="!conversationsList.length">
            <chat-contact
              v-for="conversation in conversationsList"
              :key="conversation.id"
              :userr="conversation"
              tag="li"
              :class="{ 'active': activeChatContactId === conversation.id }"
              is-chat-contact
              @click="$emit('open-chat', conversation.id)"
            />
          </b-overlay>

          </ul>
        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed } from '@vue/composition-api'
import ChatContact from './ChatContact.vue'

export default {
  components: {
    VuePerfectScrollbar,
    ChatContact,
  },
  props: {
    conversationsList: {
      type: Array,
      default: () => [],
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }


    // Search Query
    const searchQuery = ref('')
    const chatsContacts = ref([
      {
        id: 1,
        userId: 2,
        unseenMsgs: 0,
        chat: [
          {
            message: 'Hi',
            time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
            senderId: 11,
          },
          {
            message: 'Hello. How can I help You?',
            time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
            senderId: 2,
          },
          {
            message: 'Can I get details of my last transaction I made last month?',
            time: 'Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)',
            senderId: 11,
          },
          {
            message: 'We need to check if we can provide you such information.',
            time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
            senderId: 2,
          },
          {
            message: 'I will inform you as I get update on this.',
            time: 'Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)',
            senderId: 2,
          },
          {
            message: 'If it takes long you can mail me at my mail address.',
            time: 'dayBeforePreviousDay',
            senderId: 11,
          },
        ],
      },
      {
        id: 2,
        userId: 1,
        unseenMsgs: 1,
        chat: [
          {
            message: "How can we help? We're here for you!",
            time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
            senderId: 11,
          },
          {
            message: 'Hey John, I am looking for the best admin template. Could you please help me to find it out?',
            time: 'Mon Dec 10 2018 07:45:23 GMT+0000 (GMT)',
            senderId: 1,
          },
          {
            message: 'It should be Bootstrap 4 compatible.',
            time: 'Mon Dec 10 2018 07:45:55 GMT+0000 (GMT)',
            senderId: 1,
          },
          {
            message: 'Absolutely!',
            time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
            senderId: 11,
          },
          {
            message: 'Modern admin is the responsive bootstrap 4 admin template.!',
            time: 'Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)',
            senderId: 11,
          },
          {
            message: 'Looks clean and fresh UI.',
            time: 'Mon Dec 10 2018 07:46:23 GMT+0000 (GMT)',
            senderId: 1,
          },
          {
            message: "It's perfect for my next project.",
            time: 'Mon Dec 10 2018 07:46:33 GMT+0000 (GMT)',
            senderId: 1,
          },
          {
            message: 'How can I purchase it?',
            time: 'Mon Dec 10 2018 07:46:43 GMT+0000 (GMT)',
            senderId: 1,
          },
          {
            message: 'Thanks, from ThemeForest.',
            time: 'Mon Dec 10 2018 07:46:53 GMT+0000 (GMT)',
            senderId: 11,
          },
          {
            message: 'I will purchase it for sure. 👍',
            time: 'previousDay',
            senderId: 1,
          },
        ],
      },
    ])
    const contacts = [
      {
        id: 1,
        fullName: 'Felecia Rower',
        role: 'Frontend Developer',
        about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
        avatar: '@/assets/images/avatars/1.png',
        status: 'offline',
      },
      {
        id: 2,
        fullName: 'Adalberto Granzin',
        role: 'UI/UX Designer',
        about:
        'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
        avatar: '@/assets/images/avatars/2.png',
        status: 'busy',
      },
      {
        id: 3,
        fullName: 'Joaquina Weisenborn',
        role: 'Town planner',
        about:
        'Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.',
        avatar: '@/assets/images/avatars/3.png',
        status: 'busy',
      },
      {
        id: 4,
        fullName: 'Verla Morgano',
        role: 'Data scientist',
        about:
        'Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.',
        avatar: '@/assets/images/avatars/4.png',
        status: 'online',
      },
      {
        id: 5,
        fullName: 'Margot Henschke',
        role: 'Dietitian',
        about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
        avatar: '@/assets/images/avatars/5.png',
        status: 'busy',
      },
      {
        id: 6,
        fullName: 'Sal Piggee',
        role: 'Marketing executive',
        about:
        'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
        avatar: '@/assets/images/avatars/6.png',
        status: 'online',
      },
      {
        id: 7,
        fullName: 'Miguel Guelff',
        role: 'Special educational needs teacher',
        about:
        'Biscuit powder oat cake donut brownie ice cream I love soufflé. I love tootsie roll I love powder tootsie roll.',
        avatar: '@/assets/images/avatars/7.png',
        status: 'online',
      },
      {
        id: 8,
        fullName: 'Mauro Elenbaas',
        role: 'Advertising copywriter',
        about:
        'Bear claw ice cream lollipop gingerbread carrot cake. Brownie gummi bears chocolate muffin croissant jelly I love marzipan wafer.',
        avatar: '@/assets/images/avatars/12.png',
        status: 'away',
      },
      {
        id: 9,
        fullName: 'Bridgett Omohundro',
        role: 'Designer, television/film set',
        about:
        'Gummies gummi bears I love candy icing apple pie I love marzipan bear claw. I love tart biscuit I love candy canes pudding chupa chups liquorice croissant.',
        avatar: '@/assets/images/avatars/9.png',
        status: 'offline',
      },
      {
        id: 10,
        fullName: 'Zenia Jacobs',
        role: 'Building surveyor',
        about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
        avatar: '@/assets/images/avatars/10.png',
        status: 'away',
      },
    ]
    const chatsContactss = chatsContacts.value
      .map(chat => {
        const contact = contacts.find(c => c.id === chat.userId)
        contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
        return contact
      })
      .reverse()
    console.log(4444, chatsContactss)
    const filteredChatsContacts = computed(() => chatsContactss)
    const filteredContacts = []

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,
      chatsContactss,
      filteredContacts,

      // UI
      perfectScrollbarSettings,
    }
  },
}
</script>
